define("discourse/plugins/automation/admin/components/fields/da-group-field", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/models/group", "select-kit/components/group-chooser", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _object, _group, _groupChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupField extends _daBaseField.default {
    static #_ = (() => dt7948.g(this.prototype, "allGroups", [_tracking.tracked], function () {
      return [];
    }))();
    #allGroups = (() => (dt7948.i(this, "allGroups"), void 0))();
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field group-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <GroupChooser
                @content={{this.allGroups}}
                @value={{@field.metadata.value}}
                @labelProperty="name"
                @onChange={{this.setGroupField}}
                @options={{hash maximum=1 disabled=@field.isDisabled}}
              />
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "+wXp93i/",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field group-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@content\",\"@value\",\"@labelProperty\",\"@onChange\",\"@options\"],[[30,0,[\"allGroups\"]],[30,2,[\"metadata\",\"value\"]],\"name\",[30,0,[\"setGroupField\"]],[28,[32,2],null,[[\"maximum\",\"disabled\"],[1,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-group-field.js",
      "scope": () => [_daFieldLabel.default, _groupChooser.default, _helper.hash, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    constructor() {
      super(...arguments);
      _group.default.findAll({
        ignore_automatic: this.args.field.extra.ignore_automatic ?? false
      }).then(groups => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.allGroups = groups;
      });
    }
    setGroupField(groupIds) {
      this.mutValue(groupIds?.firstObject);
    }
    static #_3 = (() => dt7948.n(this.prototype, "setGroupField", [_object.action]))();
  }
  _exports.default = GroupField;
});